.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;
  @extend .clearfix;
}


main {
  width: 100%;
  > .container{
  }
  &.market{
    .module-inner{
      &.new{
        border-top: none;
      }
    }
  }
}

aside {
  float: left;
  padding: 0 1em;
  width: 270px;
  overflow: hidden;

  @include media(md) {
    float: none;
    width: 100%;
  }
}

.content-block {
  float: right;
  padding: 0 1em;
  width: calc(100% - 270px);
  min-height:calc(100vh - 99px - 49px - 233px - 48px);

  @include media(md) {
    float: none;
    width: 100%;
  }
}

section{
  // margin: 1em 0 4em;
  .container-bootstrap{
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid #ececec;
    background: $white;
  }
  .module-inner {
    padding: 0;
    margin: 0;
  }
  &:not(#landing-list){
    // background: $footerTopBgColor;
  }
  &#landing-list{
  }
  &#catalog{
    // margin: 2em 0 0;
    .module-inner {
      padding: 1em 0 0;
    }
  }
  &#news-block{
    .container-bootstrap{
      padding: 0;
    }
  }
  &#yamap{
    // padding: 0;
    background: none;
    .module-header{
      margin: 0 auto;
      max-width: $containerWidth;
      width: 100%;
      background: $white;
      padding: 1em 0;
      border-top: 1px solid #ececec;
    }
    .module-inner{
      margin: 0;
    }
    .module-wrapper{
      background: $white;
    }
  }
  .feedback{
  }
}

.mobile,
.tablet{
  section{
    padding: 0;
  }
}

html{
  &.index{
    &.section{
      .content-block{
        width: 100%;
      }
    }
  }
}