/* Список подразделов, плитки по 3 колонки */
html{
  &.section{
    .category-list{
      .category-list__item{
        border: 1px solid $borderColor;
        &:hover{
          border-color: $linkColor;
        }
      }
    }
  }
}
.category-list {
  @extend .clearfix;
  .category-list__item {
// max-width: 283px;
cursor: pointer;
float: left;
position: relative;
margin: 0 2em 2em 0;
padding: 1em;
border-radius: $borderRadius;
overflow: hidden;
-webkit-tap-highlight-color: $webkitHighlightTapColor;
> a {
  padding: 1em;
  font-size: 16px;
  display: block;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid transparent;

  @include media(xlg) {
    padding: 0 1em;
    font-size: 15px;
  }

  @include media(lg) {
    font-size: 14px;
  }
  &:hover{
    .category-list__item-image {
    }
    .category-list__item-title{
    }
  }
  .category-list__item-title {
  }
}
.subcats_exists{
  text-align: center;
  a{
    display: inline-block;
    text-decoration: none;
    padding: 0 0.5em;
  }
}
}
&._3{
  .category-list__item {
    width: calc(33.333% - 1.34em);
    &:nth-child(3n + 3) {
      margin: 0 0 2em 0;
    }
    @include media(lg) {
      width: calc(50% - 1em);
      &:nth-child(3n + 3) {
        margin: 0 2em 2em 0;
      }
      &:nth-child(2n + 2) {
        margin: 0 0 2em 0;
      }
    }
    @include media(md) {
      margin: 0 1em 1em 0;
      width: calc(33.333% - 0.666667em);
      &:nth-child(2n + 2) {
        margin: 0 1em 1em 0;
      }
      &:nth-child(3n + 3) {
        margin: 0 0 1em 0;
      }
    }
    @include media(xmd) {
      width: calc(50% - 0.5em);
      &:nth-child(3n + 3) {
        margin: 0 1em 1em 0;
      }
      &:nth-child(2n + 2) {
        margin: 0 auto 1em;
      }
    }
    @include media(xs) {
      margin: 0 auto 1em;
      float: none;
      width: 100%;
      &:nth-child(3n + 3) {
        margin: 0 auto 1em;
      }
    }
  }
}
&._4{
  .category-list__item {
    margin: 0 1em 2em 0;
    width: 100%;
    max-width: inherit;
    @media only screen and (min-width: $lg){
      width: calc(25% - 0.75em);
      margin: 0 1em 2em 0;
    }
    &:nth-child(4n + 4) {
      @media only screen and (min-width: $lg){
        margin: 0 0 2em;
      }
    }
    @media only screen and (min-width: $md) and (max-width: $lg - 1){
      width: calc(33.333% - 0.67em);
      margin: 0 1em 2em 0;
    }
    &:nth-child(3n + 3) {
      @media only screen and (min-width: $md) and (max-width: $lg - 1){
        margin: 0 0 2em 0;
      }
    }
    @media only screen and (min-width: $xs) and (max-width: $md - 1){
      width: calc(50% - 0.67em);
      margin: 0 1em 2em 0;
    }
    &:nth-child(2n + 2) {
      @media only screen and (min-width: $xs) and (max-width: $md - 1){
        margin: 0 0 2em 0;
      }
    }
  }
}
&._5{
  .category-list__item {
    margin: 0 0 2em 0;
    width: calc(50% - 0.67em);
    max-width: inherit;
    @media only screen and (min-width: $lg){
      width: calc(20% - 0.8em);
      margin: 0 1em 2em 0;
    }
    &:nth-child(5n + 5) {
      @media only screen and (min-width: $lg){
        margin: 0 0 2em;
      }
    }
    @media only screen and (min-width: $md) and (max-width: $lg - 1){
      width: calc(25% - 0.75em);
      // margin: 0 1em 2em 0;
    }
    &:nth-child(4n + 4) {
      @media only screen and (min-width: $md) and (max-width: $lg - 1){
        // margin: 0 0 2em 0;
      }
    }
    @media only screen and (min-width: $xs) and (max-width: $md - 1){
      width: calc(33.333% - 0.67em);
      // width: calc(50% - 0.67em);
      // margin: 0 1em 2em 0;
    }
    &:nth-child(3n + 3) {
      @media only screen and (min-width: $xs) and (max-width: $md - 1){
        // margin: 0 0 2em 0;
      }
    }
  }
}
.category-list__item-image {
  margin: 0 auto 1em;
  @extend .img-responsive;
  @include media(lg) {
    border-radius: $borderRadius;
  }
}
&.hidden {
  display: none;
}
}

section{
  .category-list__item{
    padding: 1em 0;
    background: $white;
    border: 1px solid $borderColor;
    &:hover{
      border-color: $linkColor;
    }
  }
}